import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Link from '../../atoms/LinkStandard';
import CustomImage from '../../atoms/CustomImage';
import SVG from '../../atoms/SVG';
import Styled from './index.styled';

const Support = ({ textBlock, support, anchorId }) => {
	const { title, copyList } = textBlock || {};
	const theme = useContext(ThemeContext);
	const fill = theme.primaryForeground;

	return (
		<Styled.Support id={anchorId || ''}>
			{textBlock && (
				<Styled.Support__Heading>
					<Styled.Support__HeadingTitle as="h2">
						{title}
					</Styled.Support__HeadingTitle>

					{copyList.map((copy, index) => (
						<Styled.Support__HeadingCopy as="p" key={index}>
							{copy}
						</Styled.Support__HeadingCopy>
					))}
				</Styled.Support__Heading>
			)}
			{support && (
				<Styled.Support__inner>
					<Styled.Support__image_wrapper>
						<CustomImage
							{...support.image}
							width="343"
							height="245"
							length="height"
						/>
					</Styled.Support__image_wrapper>

					<Styled.Support__BlockContent>
						<Styled.Support__header>
							<Styled.Support__title as="h2">
								{support.title}
							</Styled.Support__title>
							<Styled.Support__copy>{support.copy}</Styled.Support__copy>
						</Styled.Support__header>

						{support.links.map((link, index) => (
							<Styled.Support__link key={index}>
								<SVG name={link.icon} fill={fill} />

								<div>
									<Link {...link} isTitle />
									<Styled.Support__LinkCopy>
										{link.copy}
									</Styled.Support__LinkCopy>
								</div>
							</Styled.Support__link>
						))}
					</Styled.Support__BlockContent>
				</Styled.Support__inner>
			)}
		</Styled.Support>
	);
};

export default Support;
