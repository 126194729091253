import styled, { css } from 'styled-components';
import { Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing, margins } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import aspectRatio from '../../../styles/tools/aspectRatio';
import { smBodyLight, smBodyBook, xlTitleRegular } from '../../atoms/Typography/index.styled';

const Support = styled.section`
	margin-bottom: ${spacing[48]};

	${below(breakpoints[960], () => css`
		overflow: hidden;
	`)}

	${above(breakpoints[960], () => css`
		margin-bottom: ${spacing[64]};
	`)}

	${above(breakpoints[1440], () => css`
		margin-bottom: ${spacing[80]};
	`)}
`;

const Support__Heading = styled(Grid)`
	margin-top: ${spacing[48]};
	margin-bottom: ${spacing[48]};

	${above(breakpoints[960], () => css`
		margin-top: ${spacing[64]};
		margin-bottom: ${spacing[64]};
	`)}

	${above(breakpoints[1440], () => css`
		margin-top: ${spacing[80]};
		margin-bottom: ${spacing[80]};
	`)}
`;

const Support__HeadingTitle = styled(xlTitleRegular)`
	grid-column: 1 / -1;
	margin-bottom: ${gap[24]};
	color: var(--primaryForeground);

	${above(breakpoints[960], () => css`
		grid-column: 3 / span 8;
		margin-bottom: ${gap[32]};
	`)}
`;

const Support__HeadingCopy = styled(smBodyLight)`
	grid-column: 1 / -1;

	${above(breakpoints[960], () => css`
		grid-column: 3 / span 8;
	`)}
`;

const Support__inner = styled(Grid)`
	padding-bottom: ${spacing[48]};

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: calc(100% - ${spacing[48]});
		background-color: var(--primaryBackground);
		z-index: -1;
	}

	${above(breakpoints[960], () => css`
		grid-auto-rows: auto;
		align-items: center;
		padding-bottom: 0;

		&::before {
			position: relative;
			height: auto;
			grid-row: 1;
    	grid-column: 1 / 12;
			align-self: stretch;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: ${margins[48]};
			height: 100%;
			background-color: var(--primaryBackground);
		}
	`)}

	${above(breakpoints[1440], () => css`
		&::after {
			width: ${margins[80]};
		}
	`)}

	${above(breakpoints[1920], () => css`
		&::after {
			width: ${margins[120]};
		}
	`)}
`;

const Support__image_wrapper = styled.div`
	grid-column: 1 / -1;
	margin-bottom: ${spacing[48]};

	& > picture {
		padding-top: ${aspectRatio('3:2')};
		box-shadow: ${gap[16]} ${gap[16]} 0 0 var(--primaryAccent);
	}

	${below(breakpoints[960], () => css`
		width: calc(100vw - ${margins[16]});
	`)}

	${above(breakpoints[960], () => css`
		grid-column: 8 / -1;
		grid-row: 1;
		margin-bottom: ${gap[24]};
		padding-top: ${spacing[64]};
		padding-bottom: ${spacing[64]};

		& > picture {
			padding-top: ${aspectRatio('2:3')};
			box-shadow: ${gap[24]} ${gap[24]} 0 0 var(--primaryAccent);
		}
	`)}

	${above(breakpoints[1440], () => css`
		margin-bottom: ${gap[32]};
		padding-top: ${spacing[80]};
		padding-bottom: ${spacing[80]};

		& > picture {
			box-shadow: ${gap[32]} ${gap[32]} 0 0 var(--primaryAccent);
		}
	`)}
`;

const Support__BlockContent = styled.div`
	grid-column: 1 / -1;

	${above(breakpoints[960], () => css`
		grid-column: 1 / span 6;
		grid-row: 1;
		padding-top: ${spacing[64]};
		padding-bottom: ${spacing[64]};
	`)}

	${above(breakpoints[1440], () => css`
		padding-top: ${spacing[80]};
		padding-bottom: ${spacing[80]};
	`)}
`;

const Support__header = styled.div`
	margin-bottom: ${gap[32]};

	${above(breakpoints[960], () => css`
		margin-bottom: ${gap[64]};
	`)}
`;

const Support__link = styled.div`
	display: block;
	text-decoration: none;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-gap: ${gap[16]};
	margin-bottom: ${gap[32]};

	&:last-child {
		margin-bottom: ${gap[0]};
	}

	& > svg {
		width: 32px;
		height: 32px;
	}

	${above(breakpoints[600], () => css`
		grid-gap: ${gap[32]};

		& > svg {
			width: 64px;
			height: 64px;
		}
	`)}

	${above(breakpoints[960], () => css`
		margin-bottom: ${gap[48]};
	`)}
`;

const Support__title = styled(xlTitleRegular)`
	margin-bottom: ${gap[24]};
	color: var(--primaryForeground);

	${above(breakpoints[600], () => css`
		margin-bottom: ${gap[32]};
	`)}
`;

const Support__copy = styled(smBodyBook)`
	margin-bottom: ${gap[24]};

	&:last-child {
		margin-bottom: ${gap[0]};
	}
`;

const Support__icon = styled.div`
	width: 32px;
	height: 32px;

	${above(breakpoints[600], () => css`
		width: 64px;
		height: 64px;
	`)}
`;

const Support__LinkCopy = styled(smBodyLight)``;


export default {
	Support,
	Support__Heading,
	Support__HeadingTitle,
	Support__HeadingCopy,
	Support__inner,
	Support__image_wrapper,
	Support__BlockContent,
	Support__header,
	Support__link,
	Support__title,
	Support__copy,
	Support__icon,
	Support__LinkCopy
};
